export enum City {
  Chisinau = 1,
}

export const cities: Record<number, string> = {
  [City.Chisinau]: 'Chisinau',
};

export const findCityByName = (name: string) => {
  return Object.entries(cities).find(([id, city]) => city === name);
};
